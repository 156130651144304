<template>
  <div class="center-main pdHeader pdTabbar">
    <common-header class="orange"></common-header>
    <!-- content start -->

    <!-- 表单 start -->
    <div class="forms">
      <div class="form-item">
        <div class="name-box">
          <span class="name">旧密码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="password"
            placeholder="请输入旧密码"
            class="input"
            v-model="da.pwd"
            @blur="fixScroll"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="name-box">
          <span class="name">新密码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="password"
            placeholder="请输入新密码"
            class="input"
            v-model="da.newPwd"
            @blur="fixScroll"
          />
        </div>
      </div>
      <div class="form-item mb0">
        <div class="name-box">
          <span class="name">确认密码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="password"
            placeholder="请输入确认密码"
            class="input"
            v-model="da.againPwd"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="form-btns">
        <a href="javascript:" class="form-item-btn" @click="submit">提交修改</a>
      </div>
    </div>

    <!-- 表单 end -->

    <!-- content end -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { fixScroll } from "@/utils/utils";

export default {
  name: "UpdatePwd",
  data() {
    this.fixScroll = () => fixScroll();

    return {
      da: {
        pwd: "",
        newPwd: "",
        againPwd: ""
      }
    };
  },
  mounted() {
    this.da.phone = JSON.parse(this.$Cookies.get("user")).phone;
  },
  methods: {
    ...mapActions("account", ["modifyPwd"]),
    submit() {
      const d = this.da;
      if (d.pwd === "") {
        this.$toast("旧密码不能为空");
        return;
      }
      if (d.newPwd === "") {
        this.$toast("新密码不能为空");
        return;
      }

      if (d.againPwd === "") {
        this.$toast("确认密码不能为空");
        return;
      }

      if (d.againPwd !== d.newPwd) {
        this.$toast("两次密码不一致");
        return;
      }

      const da = {
        phone: this.da.phone,
        oldPwd: this.da.pwd,
        pwd: this.da.newPwd
      };

      this.modifyPwd(da).then(() => {
        this.$toast("修改成功");
        const _this = this;
        setTimeout(() => {
          _this.$router.push("/account");
        }, 2500);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.forms {
  padding: 0.4rem 0.3rem;
}

.form-btns {
  margin-top: 1rem;
}
</style>
